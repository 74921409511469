<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.loginForm">
              <h4 :class="$style.loginFormTitle">
                LOG IN
              </h4>
              <a-form class="login-form" :form="form" @submit="handleSubmit">
                <a-form-item label="メールアドレス">
                  <a-input
                    placeholder="Email"
                    v-decorator="['email', { rules: [{ required: true, message: 'メールアドレスは必須です。' }]}]"
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-item>
                <a-form-model-item ref="password" label="パスワード" prop="password">
                  <a-input-password
                    v-model="form.password"
                    placeholder="Password"
                    type="password"
                    v-decorator="['password', { rules: [{ required: true, message: 'パスワードは必須です。' }]}]"
                  >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25);"/>
                  </a-input-password>
                </a-form-model-item>
                <div class="pb20">
                  <p><router-link
                    class="pull-right"
                    to="/user/forgot"
                    :class="$style.forgetLink"
                  >パスワードを忘れた方はこちら</router-link></p>
                </div>
                <div class="form-actions" style="text-align: center;">
                  <a-button
                    htmlType="submit"
                    :class="[$style.filledBtn, $style.loginBtn]"
                  >ログイン</a-button>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$nprogress.start()
          this.$auth.login(values.email, values.password)
            .then(() => {
              // this.$nprogress.done()
              // this.$notification['success']({
              //   message: 'Logged In',
              //   description: 'You have successfully logged in to Clean UI Vue Admin Template!',
              // })
            })
            .catch((error) => {
              this.$nprogress.done()
              this.$notification['warning']({
                message: error.status + ': メールアドレスかパスワードが間違っています',
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
